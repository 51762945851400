

import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const Balance = () => {
    const [balanceDetails, setBalanceDetails] = useState([]);
    const [selectedChain, setSelectedChain] = useState("BEP20");
    const availableChains = ["BEP20", "ERC20", "TRC20", "gatsbit20"]

    const handleBep20Balance = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.adminBalanceBEP20()
            if (result?.success) setBalanceDetails(result?.balances || [])
        }
        catch (error) { alertErrorMessage(error.message); }
        finally { LoaderHelper.loaderStatus(false); }
    };

    const handleChain = (e) => {
        setSelectedChain(e.target.value)
    };

    useEffect(() => { handleBep20Balance() }, []);


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fa fa-dollar-sign"></i>
                                        </div>
                                        Wallet Balance ({selectedChain})
                                    </h1>
                                </div>
                                <div className="col-3 mt-4">
                                    <select
                                        className="form-control form-control-solid form-select form-select-dark text-center"
                                        id="exampleFormControlSelect1"
                                        value={selectedChain}
                                        onChange={handleChain}
                                    >
                                        <option value="" selected="selected" hidden="hidden">
                                            Choose here
                                        </option>
                                        {availableChains?.map((chain) => <option disabled={chain !== "BEP20"}>{chain}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    {balanceDetails.length === 0 ?
                        <h6 className="ifnoData"><img alt="" src="/assets/img/no-data.png" /> <br />No Data Available</h6>
                        :
                        <>
                            <div className="row mt-5 mb-5">
                                <div className="col mb-4">
                                    <div className="">
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                {balanceDetails?.map((item) => <div className="col-lg-6 col-xl-4 mb-4">
                                    <div className="card bg-dark text-white h-100 p-3">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="me-3">
                                                    <div className="text-white-75">{item?.token}</div>
                                                </div>
                                                <div className="fw-bold">{item?.balance}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </>}
                </div>
            </main>
        </div>
    );
};

export default Balance;
