import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";
import DataTableBase from "../../../customComponent/DataTable";

const FundsPendingWithdrawal = () => {
  const [fundWithdrawal, setFundWithdrawal] = useState([]);
  const [allData, setAllData] = useState([]);

  const linkFollow = (row) => {
    return (
      <div className="d-flex gap-2">
        <button className="btn btn-success btn-sm" type="button" onClick={() => HandleWithdrawalStatus(row?._id, row?.user_id, row?.fee, 'COMPLETE', ' ', row?.chain, row?.amount, row?.currency_id, row?.to_address)}>
          Approve
        </button>
        <button className=" btn btn-danger btn-sm" type="button" onClick={() => HandleWithdrawalStatus(row?._id, row?.user_id, row?.fee, 'REJECTED', ' ', row?.chain, row?.amount, row?.currency_id, row?.to_address)}>
          Reject
        </button>
      </div>
    );
  };

  const HandleWithdrawalStatus = async (id, userId, fee, status, Hash, chain, amount, currencyId, receiver) => {
    try {
      LoaderHelper.loaderStatus(true)
      const result = await AuthService.handleFundWithdrawal(id, userId, fee, status, Hash, chain, amount, currencyId, receiver)
      if (result?.success) {
        alertSuccessMessage(result?.message)
        handleFundWithdrawal();
      }
      else { alertErrorMessage(result?.message) }
    }
    catch (error) { alertErrorMessage(error?.message) }
    finally { LoaderHelper.loaderStatus(false) }
  };

  const columns = [
    { name: "Date", selector: row => moment(row?.createdAt).format("MMM Do YYYY"), },
    { name: "Email Id", wrap: true, selector: row => row.emailId, },
    { name: "Chain", selector: row => row.chain, },
    { name: "Coin Name", wrap: true, selector: row => row.short_name, },
    { name: "User Id", wrap: true, selector: row => row.user_id, },
    { name: "Withdrawal Address", wrap: true, selector: row => row.to_address, },
    { name: "Amount", wrap: true, selector: row => row.amount, },
    { name: "Status", grow: 1.5, selector: linkFollow, },
  ];



  const handleFundWithdrawal = async () => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.PendingWithdrwal()
      LoaderHelper.loaderStatus(false);
      if (result.success) {
        setFundWithdrawal(result.data.reverse());
        setAllData(result.data);
      }
    } catch (error) { LoaderHelper.loaderStatus(false); }
  };

  function handleSearch(e) {
    const keysToSearch = ["emailId", "chain", "short_name", "user_id", "to_address", "amount"];
    const searchTerm = e.target.value?.toLowerCase();
    const matchingObjects = allData?.reverse().filter(obj => { return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)) });
    setFundWithdrawal(matchingObjects);
  };

  useEffect(() => {
    handleFundWithdrawal();
  }, []);

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-dollar-sign"></i>
                      </div>
                      Pending Withdrawal
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-4">
              <div className="card-header">

                <div className="col-5">
                  <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={handleSearch} />
                </div>
                <div className="dropdown">
                  <button className="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Export{" "}
                  </button>
                  <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                    <CSVLink data={fundWithdrawal} className="dropdown-item">
                      Export as CSV
                    </CSVLink>
                  </div>
                </div>
              </div>
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={fundWithdrawal} />
              </div>
            </div>
          </div>
        </main>
      </div>

    </>
  );
};

export default FundsPendingWithdrawal;
